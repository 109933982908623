import React, { useEffect } from "react";
import { PageProps } from "gatsby";

import * as utils from "~/utils";
import { NodePage } from "~/graphql";
import BasicPageContent from "~/components/basicPage";

interface Props extends PageProps {
  pageContext: any;
}
export const BasicPage: React.FC<Props> = props => {
  useEffect(() => {
    utils.scrollToTop();
  }, []);

  const page: NodePage = props.pageContext.basicPage;
  return <BasicPageContent page={page} />;
};
export default BasicPage;
